import React, { useState, useEffect, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './store';
import './App.css';
import './scss/global.scss';

const About = lazy(() => import('./pages/About'));
const Blog = lazy(() => import('./pages/Blog'));
const BlogPost = lazy(() => import('./components/blog/BlogPost'));
const BuyHome = lazy(() => import('./pages/BuyHome'));
const PropertyDetails = lazy(() => import('./pages/PropertyDetails'));
const MobilePropDetails = lazy(() =>
	import('./pages/mobile/MobilePropDetails')
);
const Homepage = lazy(() => import('./pages/Homepage'));
const LenderVault = lazy(() => import('./pages/vault/LenderVault'));
const MobileSignIn = lazy(() => import('./components/users/MobileSignIn'));
const MobileSignUp = lazy(() => import('./components/users/MobileSignUp'));

function App() {
	const [isMobile, setIsMobile] = useState(false);
	const [width, setWidth] = useState(window.innerWidth);
	useEffect(() => {
		if (width < 900) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [isMobile, width]);
	return (
		<div className="App">
			<Provider store={store}>
				<Routes>
					<Route path="/" element={<Homepage />} />
					<Route path="/about" element={<About />} />
					<Route path="/buy" element={<BuyHome />} />
					<Route path="/blog" element={<Blog />} />
					<Route path="/lender-vault" element={<LenderVault />} />
					<Route path="/m-signup" element={<MobileSignUp />} />
					<Route path="/m-signin" element={<MobileSignIn />} />

					<Route path="post/:slug" element={<BlogPost />} />
					<Route
						path="/buy/property-details"
						element={isMobile ? <MobilePropDetails /> : <PropertyDetails />}
					/>
				</Routes>
			</Provider>
		</div>
	);
}

export default App;
