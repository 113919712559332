import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

const initialState = {
	user: null,
};

const userReducer = (state = initialState.user, action) => {
	switch (action.type) {
		case 'LOGIN':
			return action.payload;
		case 'LOGOUT':
			return null;
		default:
			return state;
	}
};

const rootReducer = combineReducers({
	user: userReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
